<template>
    <div>
        <Nav />
        <BreadCrumb />
        <div class="center mt20">
            <el-tabs :tab-position="'left'">
                <el-tab-pane label="最新资讯" id="news-test" v-if="false">
                    <div class="container">
                        <div class="title">
                            <span>最新资讯</span>
                        </div>
                        <div class="publicRegulate_cont">
                            <ul>
                                <li v-for="item in news.records" :key="item.id">
                                    <RouterLink :to="'/news-detail?id=' + item.id">{{ item.title }}</RouterLink>
                                    <span class="time">{{ formatDate(item.releaseTime) }}</span>
                                </li>
                            </ul>
                            <el-empty description="暂无数据" v-if="news.total === 0"></el-empty>
                        </div>
                        <div style="display: flex;flex-direction: row; justify-content:end;padding:10px 10px 20px 20px;">
                            <el-pagination background layout="prev, pager, next" :current-page="news.current" :page-count="news.pages"
                             @current-change="handNewsPageChange">
                            </el-pagination>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="通知公告">
                    <div class="container">
                        <div class="title">
                            <span>通知公告</span>
                        </div>
                        <div class="publicRegulate_cont">
                            <ul>
                                <li v-for="item in notices.records" :key="item.id">
                                    <RouterLink :to="'/news-detail?id=' + item.id">{{ item.title }}</RouterLink>
                                    <span class="time">{{ formatDate(item.releaseTime) }}</span>
                                </li>
                            </ul>
                            <el-empty description="暂无数据" v-if="notices.total === 0"></el-empty>
                        </div>
                        <div style="display: flex;flex-direction: row; justify-content:end;padding:10px 10px 20px 20px;">
                            <el-pagination background style="float: right" layout="total,  prev, pager, next, jumper" :hide-on-single-page="true"  :current-page="notices.current" :page-count="notices.pages"
                             @current-change="handNoticesPageChange">
                            </el-pagination>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="政策法规" >
                    <div class="container">
                        <div class="title">
                            <span>政策法规</span>
                        </div>
                        <div class="publicRegulate_cont">
                            <ul>
                                <li v-for="item in policys.records" :key="item.id">
                                    <RouterLink :to="'/news-detail?id=' + item.id">{{ item.title }}</RouterLink>
                                    <span class="time">{{ formatDate(item.releaseTime) }}</span>
                                </li>
                            </ul>
                            <el-empty description="暂无数据" v-if="policys.total === 0"></el-empty>
                        </div>
                        <div style="display: flex;flex-direction: row; justify-content:end;padding:10px 10px 20px 20px;">
                            <el-pagination background style="float: right" layout="total,  prev, pager, next, jumper" :hide-on-single-page="true"  :current-page="policys.current" :page-count="policys.pages"
                             @current-change="handPolicysPageChange">
                            </el-pagination>
                        </div>
                    </div>
                </el-tab-pane>
              <el-tab-pane label="交易公示">
                <div class="container">
                  <div class="title">
                    <span>交易公示</span>
                  </div>
                  <div class="publicRegulate_cont">
                    <ul>
                      <li v-for="item in trades.records" :key="item.id">
                        <RouterLink :to="'/news-detail?id=' + item.id">{{ item.title }}</RouterLink>
                        <span class="time">{{ formatDate(item.releaseTime) }}</span>
                      </li>
                    </ul>
                    <el-empty description="暂无数据" v-if="policys.total === 0"></el-empty>
                  </div>
                  <div style="display: flex;flex-direction: row; justify-content:end;padding:10px 10px 20px 20px;">
                    <el-pagination background style="float: right" layout="total,  prev, pager, next, jumper" :hide-on-single-page="true"  :current-page="policys.current" :page-count="policys.pages"
                                   @current-change="handPolicysPageChange">
                    </el-pagination>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
        </div>

        <footer2 />
    </div>
</template>

<script>
import Nav from '@/components/nav'
import footer2 from "components/footer.vue";
import CommonService from '@/service/CommonService';
import dayjs from 'dayjs';
import { RouterLink } from 'vue-router';

const pageSize = 20;

export default {
    name: 'info',
    components: {
        Nav,
        footer2,
        RouterLink
    },
    data() {
        return {
            news: [],
            policys: [],
            trades: [],
            notices: [],
            currentPolicysPage: 1,
            currentTradesPage: 1,
        }
    },
    created() {
        this.loadNews();
        this.loadNotices();
        this.loadPolicys();
        this.loadTrades();
      Nav.data().navStore.$patch({activeIndex:3})
    },
    watch: {
        currentPolicysPage(val) {
            // console.log(val);
            // CommonService.getNews({
            //     type: 2,
            //     size: pageSize,
            //     current: val,
            // }).then(res => {
            //     if (res.code === 0) {
            //         this.policys = res.data;
            //     }
            // })
        }
    },
    methods: {
        loadNews(currentPage = 1){
            CommonService.getNews({
                type: 1,
                size: pageSize,
                current: currentPage,
            }).then(res => {
                if (res.code === 0) {
                    this.news = res.data;
                }
            })
        },
        loadNotices(currentPage = 1){
            CommonService.getNews({
                type: 3,
                size: pageSize,
                current: currentPage,
            }).then(res => {
                if (res.code === 0) {
                    this.notices = res.data;
                }
            })
        },
        loadPolicys(currentPage = 1){
            CommonService.getNews({
                type: 2,
                size: pageSize,
                current: currentPage,
            }).then(res => {
                if (res.code === 0) {
                    this.policys = res.data;
                }
            })
        },
      loadTrades(currentPage = 1){
            CommonService.getNews({
                type: 6,
                size: pageSize,
                current: currentPage,
            }).then(res => {
                if (res.code === 0) {
                    this.trades = res.data;
                }
            })
        },
        formatDate(date) {
            return dayjs(date).format('YYYY-MM-DD')
        },
        handPolicysPageChange(val) {
            this.loadPolicys(val);
        },
        handNewsPageChange(val) {
            this.loadNews(val);
        },
        handNoticesPageChange(val) {
            this.loadNotices(val);
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .el-tab-pane {
    // padding: 0  30px;
}

.container {
    background: #fff;
    margin-left: 20px;

    .title {
        width: 100%;
        height: 40px;
        line-height: 40px;
        border-bottom: 1px solid #f3f3f3;
        color: #015293;
        font-size: 16px;
        background-color: #fff;
        padding-left: 12px;

        span {
            border-bottom: 1px solid #015293;
            height: 40px;
            line-height: 40px;
            display: inline-block;
            padding: 0 5px;
            font-weight: 700;
        }
    }

    .publicRegulate_cont {
        min-height: 400px;
        width: 100%;
        height: auto;

        ul {
            padding: 0 20px;
            list-style: none;
            margin-top: 12px;
        }

        li {
            width: 100%;
            height: auto;
            line-height: 38px;
            font-size: 16px;
            display: flex;
            flex-direction: row;
            color: #666;

            cursor: pointer;

            a {
                color: #666;
                flex: 1;
            }

            &::before {
                content: "\2022";
                /* Add content: \2022 is the CSS Code/unicode for a bullet */
                color: #666;
                /* Change the color */
                font-weight: normal;
                font-size: 4px;
                /* If you want it to be bold */
                display: inline-block;
                /* Needed to add space between the bullet and the text */
                width: 4em;
                /* Also needed for space (tweak if needed) */
                margin-left: -1em;
            }

            &:hover {
                color: #015293;

                a {
                    color: #015293;
                }
            }
        }
    }
}

::v-deep .el-tabs__item
{
  font-size: 18px;
}
</style>
<style lang="scss" >

.el-icon-arrow-right:before {
  content: '';//右边“下一页”三角箭头
}
.el-icon-arrow-left:before {
  content: '';//左边“上一页”三角剪头
}
.el-pager li {
  min-width: 24px;//一定要加！不然默认的宽度是32px
  width: 24px;
  height: 28px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  border-radius: 4px;
  color: #626469; //字体颜色
  background: #ffffff; //背景色
  line-height: 28px;
  &.active { //选中时字体颜色、背景色变化
    color: #fb4a3e;
    background: #0960bd center center no-repeat;
  }
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #0960bd;

}
</style>
